import { axiosApiInst } from "../../plugins/axios";
const BASE_URL = process.env.VUE_APP_BACKEND + "/individual";
/**
 * @typedef Profile
 * @property {string} mobile_no
 * @property {string} postal_code
 * @property {string} fax 
 * @property {string} first_name
 * @property {string} middle_name
 * @property {string} last_name
 * @property {string} gender
 * @property {string} district
 * @property {string} county
 * @property {string} subcounty
 * @property {string} parish
 * @property {string} village
 */
/**
 * 
 * @returns {Promise<Profile>}
 */
async function loadProfile() {
    const res = await axiosApiInst.get(BASE_URL + "/");
    return res.data;
}
/**
 * 
 * @returns {Promise<Profile>}
 */
async function saveProfile(data) {
    const res = await axiosApiInst.post(BASE_URL, data, {
        headers: {
            'Content-type': 'application/json'
        }
    });
    return res.data;
}
export { loadProfile, saveProfile }
