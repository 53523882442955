<template>
  <div>
    <b-form @submit.prevent="goTo">
      <b-row>
        <b-col>
          <b-form-group
            label="District"
            invalid-feedback="District is required"
          >
            <b-form-select v-model="$v.form.district.$model" :options="districts" :state="validateState($v.form.district)">
              <template #first>
                <b-form-select-option value="" disabled
                  >-- Please select district --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="County" invalid-feedback="County is required">
            <b-form-input
              type="text"
              v-model="$v.form.county.$model"
              :state="validateState($v.form.county)"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Sub County"
            invalid-feedback="Sub County is required"
          >
            <b-form-input
              type="text"
              v-model="$v.form.subcounty.$model"
              :state="validateState($v.form.subcounty)"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Parish" invalid-feedback="Parish is required">
            <b-form-input
              type="text"
              v-model="$v.form.parish.$model"
              :state="validateState($v.form.parish)"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Village"
              invalid-feedback="Village is required"
            >
              <b-form-input
                type="text"
                v-model="$v.form.village.$model"
                :state="validateState($v.form.village)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
      <b-alert dismissible variant="danger" v-model="error.exists">
        {{ error.body }}
      </b-alert>
      <div>
        <b-button
          class="float-left submit-btn"
          variant="outline-danger"
          @click="back"
        >
          BACK
        </b-button>
        <b-button class="submit-btn float-right" variant="danger" type="submit"
          >
          <b-spinner small v-show="saving"></b-spinner>
          SAVE</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { ACCOUNT_TYPE } from '../../auth/utils';
import ugdistricts from "./districts.json";
export default {
  mixins: [validationMixin],
  data() {
    return {
      districts: [],
      saving:false,
      form: {
        district: "",
        county: "",
        subcounty: "",
        parish: "",
        village: "",
      },
      error: {
        exists: false,
        body: "",
      },
    };
  },
  validations: {
    form: {
      district: { required },
      county: { required },
      subcounty: { required },
      parish: { required },
      village: { required },
    },
  },
  computed: {
    location() {
      return this.$store.getters["uprofile/getLocation"];
    },
  },
  mounted() {
    this.districts = ugdistricts.districts.map((el) => el.name);
    this.form.district = this.location.district;
    this.form.county = this.location.county;
    this.form.subcounty = this.location.subcounty;
    this.form.parish = this.location.parish;
    this.form.village = this.location.village;
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    goTo() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.saving = true;
      this.$store.dispatch("uprofile/saveLocation", this.form).then(() => {
        this.$store.dispatch("uprofile/saveProfile").then(()=>{
          this.$store.dispatch("auth/updateAccountType",ACCOUNT_TYPE.INDIVIDUAL).then(()=>{
            this.saving = false;
            this.$emit("next","finish");
          });
        });
      });
    },
    back() {
      this.$emit("next", "contact-info");
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  width: 10.25em;
}
</style>