
class ApplicationNotFound extends Error{
    /**
     * 
     * @param {string} type 
     */
    constructor(type){
        super();
        this.message = type + " Not Found, Please Refresh";
        this.name = "ApplicationMissing";
    }
}
class BaseAlready extends Error{
    /**
     * @param {import("./facades").Str} type
     */
    formatType(type){
        return type.capitalize().singularize();
    }
}
class AlreadyVerified extends BaseAlready{
    /**
     * @param {import("./facades").Str} type
     */
    constructor(type){
        super();
        this.message = type.capitalize().singularize()+ " Not found,likely already verified or removed";
        this.name = type.capitalize().singularize() +" Already Verified"
        this.app_type = type;
    }
} 
class AlreadyApproved extends BaseAlready{
    /**
     * @param {import("./facades").Str} type
     */
    constructor(type){
        super();
        this.message = this.formatType(type) + " not found, likely already approved or recently verified rejected by verifier";
        this.name = this.formatType(type) + " already approved";
    }
}
export {ApplicationNotFound,AlreadyVerified,AlreadyApproved}