<template>
  <div class="w-100 mb-3">
    <div class="d-inline-flex align-items-center dialog-nav">
      <div class="dialog-nav-btn">
        <b-button
          :variant="getVariant('bio-data')"
          size="sm"
          pill
          class="w-100"
          >Bio data</b-button
        >
      </div>
      <div class="dialog-nav-btn">
        <b-button :variant="getVariant('contact-info')" size="sm" pill class="w-100 dg-nav-btn"
          >Contact</b-button
        >
      </div>
      <div class="dialog-nav-btn">
        <b-button :variant="getVariant('location-info')" size="sm" pill class="w-100 dg-nav-btn"
          >Location</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    value:String
  },
  methods: {
    isActive(route_name) {
      return this.value == route_name;
    },
    getVariant(route_name){
        if(this.isActive(route_name)){
            return 'danger';
        }
        return 'outline-danger'
    }
  },
};
</script>

<style scoped>
.dialog-nav-btn {
  width: 7.5em;
}
.dialog-nav {
  flex-wrap: wrap;
  gap: 3.31em;
}

</style>