import * as api from "./api"
const store = {
    namespaced:true,
    state: {
        bio:{
            first_name:"",
            last_name:"",
            middle_name:"",
            sex:""
        },
        contact:{
            mobile_no:"",
            postal_code:"",
            fax:""
        },
        location:{
            district:"",
            county:"",
            subcounty:"",
            parish:"",
            village:""
        }
    },
    mutations: {
        set_bio(state,bio){
            state.bio = bio;
        },
        set_contact(state,contact){
            state.contact = contact;
        },
        set_location(state,location){
            state.location = location;
        }
    },
    getters:{
        getBio(state){
            return state.bio;
        },
        getContact(state){
            return state.contact;
        },
        getLocation(state){
            return state.location;
        }
    },
    actions: {
        loadProfile:async({dispatch})=>{
            const data = await api.loadProfile();
            dispatch("saveContact",{mobile_no:data.mobile_no,postal_code:data.postal_code,fax:data.fax});
            const {first_name,last_name,gender,middle_name} = data
            dispatch("saveBio",{first_name,last_name,gender,middle_name});
            const {district,county,subcounty,parish,village} =data;
            dispatch("saveLocation",{district,county,subcounty,parish,village});
        },
        saveBio({commit},bio){
          commit("set_bio",bio);  
        },
        saveContact({commit},contact){
            commit("set_contact",contact);
        },
        saveLocation({commit},location){
            commit("set_location",location);
        },
        saveProfile:async({state,dispatch})=>{
            const {contact,bio,location} = state;
            const data = await api.saveProfile({contact,bio,location});
            dispatch("saveContact",{mobile_no:data.mobile_no,postal_code:data.postal_code,fax:data.fax});
            const {first_name,last_name,gender,middle_name} = data
            dispatch("saveBio",{first_name,last_name,gender,middle_name});
            const {district,county,subcounty,parish,village} =data;
            dispatch("saveLocation",district,county,subcounty,parish,village);
            window.location.reload();
        }
    }
  }
export default store;