import '@babel/polyfill'
import 'mutationobserver-shim'

import Vue from 'vue'
import './plugins/bootstrap-vue'
import 'vue-select/dist/vue-select.css';
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import { abilitiesPlugin } from '@casl/vue';
import {ability} from './rbac/index';
import vSelect from 'vue-select';
import VuejsDialog from 'vuejs-dialog';

Vue.use(Vuelidate);
Vue.use(VuejsDialog);

Vue.use(abilitiesPlugin,ability);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false

import authModule from "./modules/auth";
import { registerModules } from "./register-modules";

export const bus  = new Vue();

registerModules({
  auth: authModule,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
