import Api from "./api";
const store = {
    namespaced:true,
    state:{
        users:[]
    },
    mutations:{
        set_users(state,users){
            state.users = users;
        },
        /**
         * 
         * @param {{users:{id:number}[]}} state 
         * @param {{id:number}} user 
         */
        add_user(state,user){
            state.users = [user,...state.users];
        },
        /**
         * 
         * @param {{users:{id:number}[]}} state 
         * @param {number[]} ids 
         */
        drop_users(state,ids){
            state.users = state.users.filter(el=>!ids.includes(el.id));
        }
    },
    getters:{
        getUsers(state){
            return state.users;
        },
        /**
         * 
         * @param {{users:{active:boolean}[]}} state 
         */
        activeUsers(state){
            return state.users.filter(el=>el.active);
        },
        /**
         * 
         * @param {{users:{active:boolean}[]}} state 
         */
        inactiveUsers(state){
            return state.users.filter(el=>!el.active);
        }

    },
    actions:{
        async loadUsers({commit}){
            const users = await Api.listUsers();
            commit("set_users",users);
        },
        addUser({commit},user){
            commit("add_user",user);
        },
        /**
         * 
         * @param {*} param0 
         * @param {number[]} ids 
         */
        removeUsers({commit},ids){
            commit("drop_users",ids);
        }
    }
};
export default store;