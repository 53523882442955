import Vue from 'vue'
import Vuex from 'vuex'
import auth from "../modules/auth/store";
import profile from "../modules/profile/store";
import newapplication from "../modules/applications/new_application/store";
import application from "../modules/applications/store";
import deposit from "../modules/deposits/store";
import rbac from "../modules/rbac/store";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appsInitialized:false,
    depsInitialized:false
  },
  mutations: {
    set_appsInitialized(state){
      state.appsInitialized = true;
    },
    set_depsInitialized(state){
      state.depsInitialized = true;
    }
  },
  actions: {

  },
  modules: {
    auth:auth,
    uprofile:profile,
    rbac,
    newapplication,
    application,
    deposit
  }
})
