import {createApi} from "./api";
const api = createApi("applications");
import { APPL_STATUS,ApplicationCollection } from ".";
import PublicationModel from "./StoreHelpers/PublicationModel";
export default {
    namespaced:true,
    state:{
        applications:new ApplicationCollection(),
    },
    mutations:{
        set_applications(state,applications){
            state.applications = new ApplicationCollection(...applications);
        },
        add_application(state,application){
            state.applications = new ApplicationCollection([application,...state.applications]);
        },
        /**
         * 
         * @param {{applications:ApplicationCollection}} state 
         * @param {{id:number}} application 
         */
        save_application(state,application){
            const applications = state.applications;
            applications.unshift(application);
            state.applications = applications;
        },
        /**
         * 
         * @param {{applications:ApplicationCollection}} state 
         * @param {*} application_id 
         */
        verify_application(state,application_id){
            const applications = new ApplicationCollection(...state.applications);
            applications.updateStatus(application_id,APPL_STATUS.VERIFIED);
            state.applications = applications;
        },
        refute_application(state,application_id){
            const applications = new ApplicationCollection(...state.applications);
            applications.updateStatus(application_id,APPL_STATUS.REJECTED_VERIFIER);
            state.applications = applications;
        },
        approve_application(state,application_id){
            const applications = new ApplicationCollection(...state.applications);
            applications.updateStatus(application_id,APPL_STATUS.APPROVED);
            state.applications = applications;
        },
        reject_application(state,application_id){
            const applications = new ApplicationCollection(...state.applications);
            applications.updateStatus(application_id,APPL_STATUS.REJECTED_APPROVER);
            state.applications = applications;
        },
        drop_application(state,application_id){
            const applications = new ApplicationCollection(...state.applications);
            applications.drop(application_id);
            state.applications = applications;
        },
        /**
         * 
         * @param {{applications:ApplicationCollection}} state 
         * @param {number} application_id 
         * @param {import("./api").Publication} publication 
         */
        save_publication(state,application_id,publication){        
            const applications = state.applications;
            const application = applications.find(el=>el.id==application_id);
            application["publications"].push(publication);
        },
        /**
         * 
         * @param {{applications:ApplicationCollection}} state 
         * @param {{action:string,data:{application_id:number,pub_id:number,isbn:string,isbn_file:string}}}
         */
        editPublication(state,{action,data}){
            const pubmodel = PublicationModel(state.applications);
            if(action=="setIsbn")
                pubmodel.setIsbn(data.application_id,data.pub_id,data.isbn,data.isbn_file);
            if(action=="drop")
                pubmodel.drop(data.application_id,data.pub_id);
        },
        update(state,application){
            const applications = state.applications;
            state.applications = applications.map(a => a.id != application.id ? a : application);
        }
    },
    getters:{
        getApplications(state){
            return state.applications;
        },
        find:(state)=>(id)=>{
            return state.applications.find(el=>el.id==id);
        }
    },
    actions:{
        insert({commit},application){
            commit("add_application",application);
        },
        saveApplication({commit},application){
            commit("save_application",application);
        },
        update({commit},application){
            commit("update",application);
        },
        approveApplication({commit},application_id){
            commit('approve_application',application_id);
        },
        rejectApplication({commit},application_id){
            commit('reject_application',application_id);
        },
        dropApplication({commit},application_id){
            commit('drop_application',application_id);
        },
        savePublication({commit},applicant_id,publication){
            commit("save_publication",applicant_id,publication);
        },
        async loadApplications({commit}){
         const data = await api.getApplications();
         commit("set_applications",data);
        },
        async initApplications({commit}){
            const data = await api.getApplications();
            commit("set_applications",data);
        },
        /**
         * 
         * @param {*} param0  
         * @param {{action:string,data:{application_id:number,pub_id:number,isbn_form:FormData}} 
         * @returns 
         */
        async modifyPublication({commit},{action,data}){
            const response = {};
            switch(action){
                case "setIsbn":{
                        const info = await api.publication.setIsbn(data["application_id"],data["pub_id"],data["isbn_form"]);
                        commit("editPublication",{action,
                            data:{application_id:data["application_id"],
                            pub_id:data["pub_id"],isbn:data["isbn_form"].get("isbn"),isbn_file:info["isbn_file"]}
                        });
                        response['isbn_file'] = info['isbn_file'];
                    }
                    break;
                case "drop":
                    await api.publication.drop(data["application_id"],data["pub_id"]);
                    commit("editPublication",{action,data});
                    break;
                default:
                    return "No such action"
            }
            return response;
        }
    }

}