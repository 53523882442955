const AIndex= ()=>import("./Applications.vue");
const ListApplications = ()=>import("./ListApplications");
const ApplicationView = () => import("./list-applications/ListView");
const moduleRoute = {
    path:"",
    component:AIndex,
    children:[
        {
            path:"",
            name:"applications",
            component:ListApplications
        },
        {
            path:"applications/:application_id",
            name:"application",
            component:ApplicationView
        }
    ],
    beforeEnter:(_to,_from,next)=>{
        const abilities = localStorage.getItem("abilities") || '[]';
        const permissions = JSON.parse(abilities);
        if(permissions.includes("view_application")){
            next();
            return 1;
        }
        next("/users");
    }
}
export default moduleRoute;