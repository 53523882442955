import { axiosApiInst } from "../../plugins/axios";
const BASE_URL = process.env.VUE_APP_BACKEND+"/authors";
/**
 * @typedef Author
 * @param {string} id
 * @param {string} name
 * @param {string} email
 */
/**
 *@param {Author} author 
 */
async function store(author){
    const res = await axiosApiInst.post(
        BASE_URL,
        author
    );
    return res.data;
}
export default {store};