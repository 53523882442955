<template>
  <b-modal title="New Application" content-class="bg-gray" id="new-application" hide-footer size="lg"  ok-only>
    <b-tabs v-model="tabIndex" pills active-nav-item-class="bg-danger">
      <b-tab title="Application Fees">
          <b-table striped hover :items="payslip"></b-table>
      </b-tab>
      <b-tab title="New Publication"> 
        <new-publication @next="nextTab" @prev="prevTab"></new-publication>
      </b-tab>
      <b-tab title="Publications">
        <publications @next="nextTab" @prev="prevTab"></publications>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import NewPublication from './NewPublication.vue';
import Publications from './Publications.vue';
export default {
  components: { NewPublication, Publications },
  data(){
    return{
        tabIndex:0,
        payslip:[
          {
            "quantity":"One Title",
            "price (UGX)":"30,000"
          },
          {
            "quantity":"Five Title",
            "price (UGX)":"100,000 (Save 50,000)"
          },
          {
            "quantity":"Ten Titles",
            "price (UGX)":"150,000 (Save 150,000)"
          }
        ]
    }
  },
  mounted(){
    this.$store.dispatch("newapplication/loadContent");
  },
  methods:{
    nextTab(){
        this.tabIndex++;
    },
    prevTab(){
        this.tabIndex--;
    }
  }
  };
</script>