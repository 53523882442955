import { token as ptoken } from "./utils";
import {ACCOUNT_TYPE} from "./utils";
import { AbilityBuilder, Ability } from '@casl/ability';

/**
 * @typedef Config
 * @property {string} account_type
 * 
 */

export default{
    namespaced:true,
    state: {
        email:null,
        phone_no:null,
        account_type:ACCOUNT_TYPE.UNKNOWN,
    },
    mutations: {
        set_email(state,email){
            state.email = email;
        },
        set_name(state,{first_name,last_name}){
            state.first_name = first_name;
            state.last_name = last_name;
        },
        set_phone(state,phone_no){
            state.phone_no = phone_no;
        },
        set_acc_type(state,acc_type){
            state.account_type = acc_type;
        }
    },
    getters:{
        getAccountType(state){
            return state.account_type;
        },
        getBio(state){
            return {"name":state.name,"phone_no":state.phone_no,"first_name":state.first_name,"last_name":state.last_name};
        }
    },
    actions: {
        logIn({commit,dispatch},{email,token,first_name,last_name,phone_no,account_type,permissions}){
            commit("set_email",email);
            commit("set_name",{first_name,last_name});
            commit("set_phone",phone_no);
            dispatch("updateAccountType",account_type);
            localStorage.setItem("email",email);
            localStorage.setItem("name",JSON.stringify({first_name,last_name}));
            localStorage.setItem("phone_no",phone_no);
            const { can, rules } = new AbilityBuilder(Ability);
            can(permissions);
            localStorage.setItem("abilities",JSON.stringify(permissions));
            ptoken.save(token);
            return rules;
        },
        loadCredentials({commit}){
            const email = localStorage.getItem("email")||"";
            const name = localStorage.getItem("name")||"{}";
            const phone_no = localStorage.getItem("phone_no")||"";
            const account_type = localStorage.getItem("account_type")||ACCOUNT_TYPE.UNKNOWN;
            commit("set_email",email);
            commit("set_name",JSON.parse(name));
            commit("set_phone",phone_no);
            commit("set_acc_type",account_type);

        },
        /**
         * 
         * @param {*} param0 
         * @param {string} account_type
         */
        updateAccountType({commit},account_type){
            commit("set_acc_type",account_type);
            localStorage.setItem("account_type",account_type);
        },
        logOut({commit}){
            commit("set_email",null);
            commit("set_name",{first_name:null,last_name:null});
            commit("set_phone",null);
            commit("set_acc_type",ACCOUNT_TYPE.UNKNOWN);
            localStorage.clear();
        }
    }
}