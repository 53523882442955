<template>
  <div class="dialog-container">
    <div class="w-100 pb-4">
      <h5 class="text-center mb-4">Select Account Type</h5>
      <div class="action-btns mx-auto">
        <b-button variant="outline-danger" class="dialog-btn" disabled
          >ORGANISATION</b-button
        >
        &nbsp;
        <b-button variant="danger" class="dialog-btn" @click="goTo"
          >INDIVIDUAL</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goTo() {
      const { first_name,last_name, phone_no } = this.$store.getters["auth/getBio"];
      this.$store.dispatch("uprofile/saveContact", {
        mobile_no: phone_no,
        postal_code: "",
        fax: "",
      });
      this.$store
        .dispatch("uprofile/saveBio", {
          first_name,
          last_name,
          middle_name: "",
          sex: "",
        })
        .then(() => {
          this.$emit("next", "bio-data");
        });
    },
  },
};
</script>

<style scoped>
.dialog-container {
  padding-top: 2em;
  padding-bottom: 3em;
  display: flex;
  align-items: center;
}
.action-btns {
  width: fit-content;
}
.dialog-btn {
  height: 2.4375em;
  width: 10.25em;
}
</style>