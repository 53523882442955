import aroutes from "./router";
const PAY_STATUS = {
    PAID: "paid",
    PENDING: "pending",
  };
const APPL_STATUS = {
  VERIFIED:'verified',
  UNVERIFIED:'unverified',
  APPROVED:'approved',
  DEFER_APPROVER:'defered/approver',
  REJECTED_VERIFIER:'rejected/verifier',
  REJECTED_APPROVER:'rejected/approver'
};
class ApplicationCollection extends Array{
/**
 * 
 * @param {number} id 
 */
  findOne(id){
    const application = this.find(el=>el.id==id);
    return application;
  }
/**
 * 
 * @param {number} application_id 
 */
  drop(application_id){
    const idx = this.findIndex(el=>el.id==application_id);
    this.splice(idx,1);
  }
/**
 * 
 * @param {number} application_id 
 * @param {string} status 
 */
  updateStatus(application_id,status){
    const idx = this.findIndex(el=>el.id==application_id);
    const application = this.findOne(application_id);
    application.status = status;
    this[idx] = application;
  }
}
export{
    aroutes,
    PAY_STATUS,
    APPL_STATUS,
    ApplicationCollection
}