<template>
  <div class="sbar-btn-w mx-auto sbar-ln" :class="{'ln-active':active}">
    <router-link  :to="to">
      <div class="d-flex align-items-center" :class="{'ln-txt-active':active}">
        <div>
          <slot></slot>
        </div>
        <div class="col-10 pl-3 text-capitalize">{{status}}</div>
        <div v-show="!active">{{count|hideZero}}</div>
      </div>
    </router-link>
  </div>
</template>
<style scoped>
a{
  color:var(--dash-black);
}
.ln-txt-active{
  color: var(--dash-red);
}
.ln-active{
  background-color:var(--dash-red-pale);
  border-radius: 0.15em;
}
a:hover{
    text-decoration: none;
}
</style>
<script>
export default {
  props: ["to","status","count"],
  computed:{
    active(){
      return this.to==decodeURIComponent(this.$route.fullPath);

    }
  },
  filters:{
    hideZero(count){
      return count?count:""
    }
  }
};
</script>

<style>
</style>