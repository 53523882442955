import { axiosApiInst } from "../../plugins/axios";
const BASE_URL = process.env.VUE_APP_BACKEND+"/auth";
async function signOut(){
    const res = await axiosApiInst({
        url:BASE_URL+"/signout",
        method:"post"
    });
    return res;
}
/**
 * @typedef Authenticated
 * @property {string} token
 * @property {string} name
 * @property {string} role
 * @property {string} account_type
 * @property {string} phone_no
 * @property {string[]} permissions
 */

/**
 * 
 * @param {string} email 
 * @param {string} password 
 * @returns {<Promise<{data:Authenticated}>>}
 */
async function logIn(email,password){
    const url = BASE_URL+"/login"
    const res = await axiosApiInst.post(url,null,{
        auth:{
            username:email,
            password
        }
    });
    return res;
}
/**
 * @typedef Activate
 * @property {string} email 
 * @property {string} password 
 * @property {string} confirm_password
 * @property {string} activation_code 
 */
/**
 * 
 * @param {Activate} data 
 * @return {Promise<{data:Authenticated}>}
 */
async function activate(data){
    const url = BASE_URL+"/activate";
    const res = await axiosApiInst.post(url,data);
    return res;
}
/**
 * @param {string} email
 */
async function getPasswordLink(email){
    const url = process.env.VUE_APP_BACKEND+"/account/forgot-password";
    const res = await axiosApiInst.post(url,{email});
    return res;   
}

async function resetPassword(data){
    const url = process.env.VUE_APP_BACKEND+"/account/password/reset";
    const res = await axiosApiInst.post(url,data);
    return res;   
}

export {logIn,signOut,activate,getPasswordLink,resetPassword
}