import { AlreadyApproved } from "../applications/exceptions";
import { axiosApiInst } from "../../plugins/axios";
import { Str } from "../applications/facades";
class ApproverAdaptor {
  /**
   *
   * @param {string} type
   */
  constructor(type) {
    this.type = type;
    this.baseUrl = process.env.VUE_APP_BACKEND+"/"+this.type;
    this.approvalUrl = (id) => this.baseUrl + "/" + id + "/approvals";
  }
  /**
   *
   * @param {number} id
   */
  async approve(id) {
    try {
      const res = await axiosApiInst({
        url: this.approvalUrl(id),
        method: "post",
      });
      return res.data;
    } catch (err) {
      if (err.response.status == 404) {
        throw new AlreadyApproved(new Str(this.type));
      }
    }
  }
  /**
   *
   * @param {number} id
   * @param {string} comments
   * @returns
   */
  async reject(id, comments) {
    try {
      const res = await axiosApiInst({
        url: this.approvalUrl(id),
        method: "delete",
        data: { comments },
      });
      return res.data;
    } catch (err) {
      if (err.response.status == 404) {
        throw new AlreadyApproved(new Str(this.type));
      }
    }
  }
  /**
   * 
   * @param {number} id 
   * @returns {Promise<string>} 
   */
  async comments(id){
    const res = await axiosApiInst({
      url:this.approvalUrl(id)+"/comments",
      method:"get"
    });
    return res.data;
  }
/**
 * 
 * @param {number} id 
 * @param {string} comments
 */
  async defer(id,comments){
    const res = await axiosApiInst.put(
      this.approvalUrl(id),
      {comments}
    );
    return res.data;
  }
}
export default ApproverAdaptor;
