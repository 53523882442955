class Token{
    /**
     * 
     * @param {string|null} tk_str 
     */
    constructor(tk_str){
        this.token_str = tk_str;
    }
    static fromStorage(){
        const token_str = localStorage.getItem("token")|| null;
        return new Token(token_str);
    }
    delete(){
        this.token_str = null;
    }
    isValid(){
        if(this.token_str){
            return true; 
        }
        return false;
    }
    /**
     * 
     * @param {string} token_str 
     */
    save(token_str){
        this.token_str = token_str;
        localStorage.setItem("token",token_str);
    }
}
const token = Token.fromStorage();
const ACCOUNT_TYPE={
    ORGANISATION:'Organisation',
    INDIVIDUAL:'Individual',
    OTHER:'Other',
    UNKNOWN:'Unknown'
  }
export {token,ACCOUNT_TYPE}