<template>
  <div>
    <b-form @submit.prevent="goTo">
      <b-row>
        <b-col>
          <b-form-group label="Mobile No.">
            <b-form-input type="tel" disabled 
            v-model="$v.form.mobile_no.$model"
            :state="validateState($v.form.mobile_no)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Postal Code">
            <b-form-input type="text" v-model="form.postal_code"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <div class="col-6">
          <b-form-group label="Fax">
            <b-form-input type="text" v-model="form.fax"></b-form-input>
          </b-form-group>
        </div>
      </b-row>
      <div>
        <b-button class="float-left submit-btn" variant="outline-danger" @click="back">
          BACK
        </b-button>
        <b-button variant="danger" class="submit-btn float-right" type="submit">
          NEXT
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins:[validationMixin],
  data(){
    return{
      form:{
        mobile_no:"",
        postal_code:"",
        fax:""
      }
    }
  },
  validations:{
    form:{
      mobile_no:{required}
    }
  },
  computed:{
    contact(){
      return this.$store.getters["uprofile/getContact"]
    }
  },
  mounted(){
    this.form.mobile_no = this.contact["mobile_no"];
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    goTo() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.$store.dispatch("uprofile/saveContact",this.form).then(()=>{
        this.$emit("next", "location-info");
      })
    },
    back() {
      this.$emit("next", "bio-data");
    },
  },
};
</script>

<style scoped>
.submit-btn {
  width: 10.25em;
}
</style>