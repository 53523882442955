<template>
  <div class="pt-2">
    <b-form @submit.prevent="goTo">
      <b-row>
        <b-col>
          <b-form-group
            label="First Name"
            invalid-feedback="First Name is required"
          >
            <b-form-input
              type="text"
              v-model="$v.form.first_name.$model"
              disabled
              :state="validateState($v.form.first_name)"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Last Name">
            <b-form-input
              type="text"
              v-model="$v.form.last_name.$model"
              disabled
              :state="validateState($v.form.last_name)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Middle Name">
            <b-form-input type="text" v-model="form.middle_name"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Gender" invalid-feedback="Gender is required">
            <b-form-select
              v-model="$v.form.gender.$model"
              :state="validateState($v.form.gender)"
            >
              <b-form-select-option value=""
                >Please Select Sex</b-form-select-option
              >
              <b-form-select-option value="Male">Male</b-form-select-option>
              <b-form-select-option value="Female">Female</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button class="float-right submit-btn" variant="danger" type="submit">
        NEXT
      </b-button>
    </b-form>
  </div>
</template>
<style scoped>
.submit-btn {
  width: 10.25em;
}
</style>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        gender: "",
        first_name: "",
        last_name: "",
        middle_name: "",
      },
    };
  },
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      gender: { required },
    },
  },
  computed: {
    bio() {
      return this.$store.getters["uprofile/getBio"];
    },
  },
  mounted() {
    this.preFill();
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    preFill() {
      this.form.first_name = this.bio.first_name;
      this.form.last_name = this.bio.last_name;
      if (this.bio["gender"] != "") {
        this.form.gender = this.bio["gender"];
      }
    },
    goTo() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.$store.dispatch("uprofile/saveBio", this.form).then(() => {
        this.$emit("next", "contact-info");
      });
    },
  },
};
</script>