import { axiosApiInst } from "../../plugins/axios";
import { AlreadyVerified } from "../applications/exceptions";
import { Str } from "../applications/facades";
class VerifierAdaptor {
  /**
   *
   * @param {string} type
   */
  constructor(type) {
    this.type = type;
    this.baseUrl = process.env.VUE_APP_BACKEND + "/" + type;
    this.specify = (/** @type {number} **/ id) => this.baseUrl + "/" + id;
    this.verificationUrl = (/** @type {number} **/ id) =>
      this.specify(id) + "/verification";
  }
  /**
   * Verifier Settles payments
   * @param {number} id
   * @param {string} receipt_no
   */
  async settle(id, receipt_no) {
    const res = await axiosApiInst({
      url: this.specify(id) + "/payment",
      method: "put",
      data: { receipt_no },
    });
    return res.data;
  }
  /**
   *
   * @param {number} id
   * @param {string} comments
   * @returns
   */
  async verify(id, comments) {
    try {
      const res = await axiosApiInst({
        url: this.verificationUrl(id),
        method: "post",
        data: { comments },
      });
      return res.data;
    } catch (err) {
      if (err.response.status == 404) {
        throw new AlreadyVerified(new Str(this.type));
      }
    }
  }
  /**
   *
   * @param {number} id
   * @param {string} comments
   * @returns
   */
  async reject(id, comments) {
    const res = await axiosApiInst({
      url: this.verificationUrl(id),
      method: "delete",
      data: { comments },
    });
    return res.data;
  }
    /**
     *
     * @param {number} id
     * @returns {Promise<string>}
     */
     async comments(id) {
      const res = await axiosApiInst({
        url: this.verificationUrl(id)+"/comments",
      });
      return res.data;
    }
}
export default VerifierAdaptor;
