
const AuthView = () => import("./AuthLayout.vue");
const SignUpView = () => import("./SignUpView.vue");
const ActivationView = () => import("./ActivationView");
const ResendLinkView = () => import("./ForgotPassword.vue");
const ResetPassword = () => import("./ResetPassword.vue");
const LogInView = () => import("./LoginView");

const moduleRoute = {
    path: "/auth",
    component: AuthView,
    children: [
        {
            path:"login",
            name:"auth:login",
            component:LogInView
        },
        {
            path: "register",
            name:"auth:register",
            component: SignUpView
        },
        {
            path:"activate",
            name:"auth:activate",
            component:ActivationView
        },
        {
            path:"forgot_password",
            name:"auth:forgot_password",
            component:ResendLinkView
        },
        {
            path:"reset-password",
            name:"auth:reset_password",
            component:ResetPassword
        }
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
}