import { axiosApiInst } from "../../../plugins/axios"
/**
 * 
 * @param {number} application_id
 * @param {number} pub_id
 * @param {string} action 
 * @returns {string}
 */
const BASE_URL = (application_id,pub_id,action) => process.env.VUE_APP_BACKEND + 
"/applications/"+application_id+"/publication/"+pub_id+"/"+ action


export default {
    /**
     * 
     * @param {number} application_id 
     * @param {number} pub_id 
     * @param {FormData} isbn_form 
     */
    async setIsbn(application_id,pub_id,isbn_form){
        const res = await axiosApiInst({
            url:BASE_URL(application_id,pub_id,"setIsbn"),
            method:"post",
            data:isbn_form
        });
        return res.data;
    },
    /**
     * 
     * @param {number} application_id 
     * @param {number} pub_id 
     * @returns 
     */
    async drop(application_id,pub_id){
        return await axiosApiInst({
            url:BASE_URL(application_id,pub_id,"drop"),
            method:"post",
        })
    }
}