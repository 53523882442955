<template>
  <b-modal id="new-author" hide-footer title="New Author" size="sm">
    <b-form @submit.prevent="save">
        <b-form-group label="Name">
            <b-form-input required disabled :value="name"></b-form-input>
        </b-form-group>
        <b-form-group label="Email">
            <b-form-input type="email" v-model="email" required></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="danger">
            <b-spinner small v-show="saving"></b-spinner>
            ADD AUTHOR
        </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import api from "./api";
export default {
    props:["name"],
    data(){
        return {
            email:"",
            saving:false
        }
    },
    methods:{
        save(){
            this.saving = true;
            api.store({email:this.email,name:this.name}).then((author)=>{
                this.saving = false;
                this.$store.dispatch("newapplication/addAuthor",author).then(()=>{
                    this.$emit("new-athr",author);
                    this.$bvModal.hide("new-author");
                    this.email = "";
                })
            })
        }
    }
}
</script>

<style>

</style>