
/**
 * 
 * @param {import("../").ApplicationCollection} applications 
 * @returns 
 */
 export default function (applications){
    return {
        /**
         * @param {number} application_id
         * @param {number} pub_id
         * @param {string} isbn
         * @param {string} isbn_file
         */
        setIsbn(application_id,pub_id,isbn,isbn_file){
            const application = applications.find(application=>application.id==application_id);
            const publication = application["publications"].find(publication=>publication.id==pub_id);
            publication["isbn"] = isbn;
            publication["isbn_file"] = process.env.VUE_APP_STATIC +"/"+ isbn_file;
        },
        /**
         * 
         * @param {number} application_id 
         * @param {number} pub_id 
         */
        drop(application_id,pub_id){
            const application = applications.find(application=>application.id==application_id);
            application["publications"] = application["publications"].filter(publication=>publication.id!=pub_id);
        }
    }
}