<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
@import "@/assets/custom_scss/_variables.scss";
@import "@/assets/custom_scss/main.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
:root {
  --dash-red: #ad0e1d;
  --dash-black: #606060;
  --dash-red-pale: #f2c9c9;
  --dash-white: #edf2f4;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.dg-btn--ok {
  border-color:var(--dash-red);
  color:var(--dash-red);
}
.dg-btn--cancel {
  background-color: var(--dash-red);
}
</style>
<script>
import { AbilityBuilder, Ability } from "@casl/ability";
import { ability } from "./rbac";
export default {
  created() {
    this.$store.dispatch("auth/loadCredentials");
    this.loadAbilities();
  },
  methods: {
    loadAbilities() {
      const abilities = localStorage.getItem("abilities");
      if (abilities) {
        const permissions = JSON.parse(abilities);
        const { can, rules } = new AbilityBuilder(Ability);
        can(permissions);
        this.$ability.update(rules);
        ability.update(rules);
      }
    },
  },
};
</script>