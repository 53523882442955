import { axiosApiInst } from "../../plugins/axios";
const BASE_URL = process.env.VUE_APP_BACKEND+"/publishers"
/**
 * @typedef Publisher
 * @property {string} name
 * @property {string} physical_address
 * @property {string} postal_address
 */
/**
 * 
 * @param {Publisher} publisher 
 */
async function store(publisher){
    const res = await axiosApiInst.post(  
        BASE_URL,
        publisher
    );
    return res.data;
}
export {store}