const ReportView = () => import("./ReportMain.vue");
const ISBNView = ()=>import("./Applications.vue");
const DepositView = () => import("./Deposits.vue");
const PaymentsView = () => import("./Payments.vue");
const moduleRoute = {
    path:"reports",
    component:ReportView,
    children:[
        {
            path:"",
            name:"isbn_report",
            component:ISBNView
        },
        {
            path:"deposits",
            name:"deposit_report",
            component:DepositView
        },
        {
            path:"payments",
            name:"payments_report",
            component:PaymentsView
        }
    ]
}
export default moduleRoute;