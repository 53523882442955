<template>
  <b-dropdown
    id="my-nav-dropdown"
    no-caret
    class="w-100"
    size="md"
    variant="light"
    dropup
  >
    <template #button-content>
      <div class="d-flex align-items-center text-coal">
        <b-avatar class="mr-3" v-show="!loggingOut"></b-avatar>
        <b-spinner v-show="loggingOut" class="mr-3"></b-spinner>
        <span class="mr-auto">{{ name }}</span>
        <b-icon-caret-up-fill></b-icon-caret-up-fill>
      </div>
    </template>
    <b-dropdown-item @click="dispProfile" v-show="$can('create_application')">Profile</b-dropdown-item>
    <b-dropdown-divider v-show="$can('create_application')"></b-dropdown-divider>
    <b-dropdown-item @click="logOut">Log Out</b-dropdown-item>
  </b-dropdown>
</template>
<script>
import auth from "../modules/auth";
export default {
  computed: {
    name() {
      return this.$store.state.auth.first_name+" "+this.$store.state.auth.last_name;
    },
  },
  data(){
    return {
      loggingOut:false
    }
  },
  methods: {
    logOut() {
      this.loggingOut = true;
      auth.api.signOut().then(() => {
        this.$store.dispatch("auth/logOut").then(() => {
          this.loggingOut = false;
          this.$router.push({ path: "/auth/login" });
        });
      });
    },
    dispProfile(){
      this.$emit("show-profile");
    }
  },
};
</script>

<style scoped>
</style>