<template>
  <div>
    <b-table thead-class="d-none" :fields="fields" :items="publications">
        <template #cell(actions)="data">
            <b-button variant="link" @click="dropPub(data.index)">
                <b-icon-trash class="text-danger"></b-icon-trash>
            </b-button>
        </template>
    </b-table>
    <b-alert variant="info" show>
        Estimated Fees: UGX {{ fees.toLocaleString() }}
    </b-alert>
    <b-alert variant="success" dismissible v-model="appCreated">
        Application creation is a success. Please review the application 
        <b-link :to="{'name':'application','params':{ application_id }}" v-if="application_id">here</b-link> and make payment.
    </b-alert>
    <div class="d-flex justify-content-between">
        <b-button variant="outline-danger" @click="prevTab" class="w-25" size="sm">BACK</b-button>
        <b-button variant="danger" @click="onSubmit" class="w-25" size="sm">FINISH</b-button>
    </div>
  </div>
</template>

<script>
export default {
    computed:{
        publications(){
            return this.$store.getters["newapplication/getPublications"];
        },
        authors(){
            return this.$store.getters["newapplication/getFullAuthors"];
        },
        fees(){
            let start = this.publications.length;
            let total = 0;
            while(start > 0){
               if(start >= 10){
                start-=10;
                total+=150000;
               }else if (start >= 5){
                start-=5;
                total+=100000;
               }else{
                total += 30000;
                start-=1;
               }
            }
            return total;
        }
    },
    data(){
        return {
            fields:[
                "tentative_title",
                "edition",
                {key:"author",formatter:(author_id)=>{
                    return this.authors.find(el=>el.id==author_id)['name'];
                }},
                "actions"
            ],
            appCreated:false,
            application_id:null,
        }
    },
    methods:{
        /**
         * 
         * @param {number} idx 
         */
        dropPub(idx){
            this.$store.dispatch("newapplication/dropPub",idx);
        },
        prevTab(){
            this.$emit("prev");
        },
        onSubmit(){
            this.$store.dispatch('newapplication/savePublications').then((appl)=>{
                this.appCreated = true;
                this.application_id = appl.id;
                this.$store.dispatch('application/loadApplications');
                setTimeout(()=>{
                    this.$bvModal.hide("new-application");
                },5000);
            });
        }
    }
}
</script>

<style>

</style>