import {createApi} from "../applications/api";
const api = createApi("deposits"); 
import { APPL_STATUS,ApplicationCollection} from "../applications";

export default {
    namespaced:true,
    state:{
        deposits:[],
    },
    mutations:{
        set_applications(state,applications){
            state.deposits = applications;
        },
        /**
         * 
         * @param {{applications:ApplicationCollection}} state 
         * @param {{id:number}} application 
         */
        save_application(state,application){
            const applications = new ApplicationCollection(...state.deposits);
            applications.unshift(application);
            state.deposits = applications;
        },
        /**
         * 
         * @param {{applications:ApplicationCollection}} state 
         * @param {*} application_id 
         */
        verify_application(state,application_id){
            const deposits = new ApplicationCollection(...state.deposits);
            deposits.updateStatus(application_id,APPL_STATUS.VERIFIED);
            state.deposits = deposits;
        },
        refute_application(state,application_id){
            const deposits = new ApplicationCollection(...state.deposits);
            deposits.updateStatus(application_id,APPL_STATUS.REJECTED_VERIFIER);
            state.deposits = deposits;
        },
        approve_application(state,application_id){
            const deposits = new ApplicationCollection(...state.deposits);
            deposits.updateStatus(application_id,APPL_STATUS.APPROVED);
            state.deposits = deposits;
        },
        reject_application(state,application_id){
            const deposits = new ApplicationCollection(...state.deposits);
            deposits.updateStatus(application_id,APPL_STATUS.REJECTED_APPROVER);
            state.deposits = deposits;
        },
        drop_application(state,application_id){
            const deposits = new ApplicationCollection(...state.deposits);
            deposits.drop(application_id);
            state.deposits = deposits;
        },
        /**
         * 
         * @param {{applications:ApplicationCollection}} state 
         * @param {number} application_id 
         * @param {import("../applications/api").Publishing} publishing
         */
        save_publishing(state,application_id,publishing){        
            const deposits = state.deposits;
            const deposit = deposits.find(el=>el.id==application_id);
            // console.log(publication);
            deposit["publishings"].push(publishing);
        
        },
        update(state,deposit){
            const deposits = state.deposits;
            state.deposits = deposits.map(a => a.id != deposit.id ? a : deposit);
        },
        insert(state,deposit){
            const deposits = state.deposits;
            state.deposits = [deposit,...deposits];
        } 
    },
    getters:{
        getApplications(state){
            return state.deposits;
        },
        find:(state)=>(id)=>{
            return state.deposits.find(el=>el.id==id);
        }
    },
    actions:{
        saveApplication({commit},application){
            commit("save_application",application);
        },
        update({commit},deposit){
            commit("update",deposit);
        },
        insert({commit},deposit){
            commit("insert",deposit);
        },
        approveApplication({commit},application_id){
            commit('approve_application',application_id);
        },
        rejectApplication({commit},application_id){
            commit('reject_application',application_id);
        },
        dropApplication({commit},application_id){
            commit('drop_application',application_id);
        },
        savePublication({commit},applicant_id,publication){
            commit("save_publishing",applicant_id,publication);
        },
        async loadApplications({commit}){
         const data = await api.getApplications();
         commit("set_applications",data);
        },
        async initApplications({commit}){
            const data = await api.getApplications();
            commit("set_applications",data);
        }
    }

}