import { axiosApiInst } from "../../plugins/axios";
import PubApi from "./ApiHelpers/PubApi";
import { ApplicationNotFound } from "./exceptions";
import VerifierAdaptor from "../shared/VerifierAdaptor";
import ApproverAdaptor from "../shared/ApproverAdaptor";
/**
 * @typedef ApplicationType
 * @property {string} payment_ref
 * @property {string} payment_status
 * @property {Array<{tentative_title:string}} publications
 * @property {string} created_at
 */

/**
 * @typedef Publication
 * @property {number} id
 * @property {string} tentative_title
 */

/**
 * @typedef Publishing
 * @property {number} id
 * @property {boolean} loading
 */

/**
 * @typedef Publishing
 * @property {string} publication_title
 * @property {boolean} loading
 */
/**
 * @class
 * @property {Publication[]} publications
 * @property {Publishing[]} publishings
 */
class Application {
  /**
   *
   * @param {ApplicationType} data
   */
  constructor(data) {
    for (let j in data) {
      this[j] = data[j];
    }
    this["deleting"] = false;
    this["_showDetails"] = false;
    if (data["publishings"]) {
      this["publishings"] = data["publishings"].map((element) => {
        element["loading"] = false;
        return element;
      });
    }
    if (data["publications"]) {
      this["publications"] = data["publications"].sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    }
  }
  /**
   *
   * @param {Publication} pub
   */
  savePublication(pub) {
    this.publications.push(pub);
  }
  fees(){
    let start = this.publications.length;
    let total = 0;
    while(start > 0){
       if(start >= 10){
        start-=10;
        total+=150000;
       }else if (start >= 5){
        start-=5;
        total+=100000;
       }else{
        total += 30000;
        start-=1;
       }
    }
    return total;
}
}

/**
 *
 * @param {string} type
 * @returns
 */
function createApi(type) {
  const BASE_URL = process.env.VUE_APP_BACKEND + "/" + type;
  const verifier = new VerifierAdaptor(type);
  const approver = new ApproverAdaptor(type);
  return {
    publication: PubApi,
    verifier,
    approver,
    /**
     *
     * @param {Blob} receipt
     * @param {number} id
     * @returns
     */
    async pay(receipt, id) {
      const data = new FormData();
      data.append("payment_receipt", receipt);
      const res = await axiosApiInst.post(
        BASE_URL + "/" + id + "/payment",
        data
      );
      return res.data;
    },
    /**
     *
     * @returns {number}
     */
    async getPaymentDetails(id) {
      const res = await axiosApiInst.get(BASE_URL +"/"+id+"/payment_details");
      return res.data;
    },

    /**
     * @param {number} id
     */
    async getReceipt(id) {
      try{
        const res = await axiosApiInst.get(BASE_URL+"/"+id+"/payment", {
          responseType: "blob",
        });
        const blob = new Blob([res.data]);
        const path = URL.createObjectURL(blob)
        return path;
      }catch(error){
        if(error.response.status==404){
          return null;
        }
      }
    },
    /**
     * @return {Promise<{publishers:string[],authors:string[]}>}
     */
    async getAppData() {
      const res = await axiosApiInst(BASE_URL + "/app-data");
      return res.data;
    },
    /**
     * @param {Array<any>} publications
     * @return {Promise<Application>}
     */
    async createApplication(publications) {
      const formData = new FormData();
      publications.forEach((publication, index) => {
        formData.append(`data[${index}][tentative_title]`, publication.tentative_title);
        formData.append(`data[${index}][cover_page_img]`, publication.cover_page_img);
        formData.append(`data[${index}][edition]`, publication.edition);
        formData.append(`data[${index}][number_of_titles]`, publication.number_of_titles);
        formData.append(`data[${index}][cover_page_title]`, publication.cover_page_title);
        formData.append(`data[${index}][nature]`, publication.nature);
        formData.append(`data[${index}][first_pub_year]`, publication.first_pub_year);
        formData.append(`data[${index}][publisher]`, publication.publisher);
        formData.append(`data[${index}][author]`, publication.author);
      });
      
      const res = await axiosApiInst.post(BASE_URL, formData);
      return res.data;
    },

    async createDeposit(publishings){
      const res = await axiosApiInst.post(BASE_URL,publishings);
      return res.data;
    },
    /**
     *
     * @returns {Promise<Application[]>}
     */
    async getApplications() {
      const res = await axiosApiInst.get(BASE_URL);
      return res.data.map((el) => {
        return new Application({ ...el, loadedPubs: false });
      });
    },
    /**
     * @param {number} applicant_id
     */
    async getPublications(applicant_id) {
      const res = await axiosApiInst.get(
        BASE_URL + "/" + applicant_id + "/publications"
      );
      const pubs = res.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return pubs;
    },

    /**
     *
     * @param {number} id
     */
    async dropApplication(id) {
      try {
        const res = await axiosApiInst({
          url: BASE_URL + "/" + id,
          method: "delete",
        });
        return res.data;
      } catch (err) {
        throw new ApplicationNotFound(type);
      }
    },
    /**
     *
     * @param {number} applicant_id
     * @param {{tentative_title:string,cover_page_title:string}} publication
     */
    async addPublication(applicant_id, publication) {
      let index = 0;
      const formData = new FormData();
      formData.append(`data[${index}][tentative_title]`, publication.tentative_title);
      formData.append(`data[${index}][cover_page_img]`, publication.cover_page_img);
      formData.append(`data[${index}][edition]`, publication.edition);
      formData.append(`data[${index}][number_of_titles]`, publication.number_of_titles);
      formData.append(`data[${index}][cover_page_title]`, publication.cover_page_title);
      formData.append(`data[${index}][nature]`, publication.nature);
      formData.append(`data[${index}][first_pub_year]`, publication.first_pub_year);
      formData.append(`data[${index}][publisher]`, publication.publisher);
      formData.append(`data[${index}][author]`, publication.author);
      try {
        const res = await axiosApiInst.post(
          BASE_URL + "/" + applicant_id + "/publications",
          formData
        );
        return res.data;
      } catch (err) {
        if (err.response.status == 404) {
          throw new ApplicationNotFound(type);
        }
      }
    },
    /**
     *
     * @param {number} application_id
     */
    async getApplicant(application_id) {
      const res = await axiosApiInst({
        url: BASE_URL + "/" + application_id + "/applicant",
      });
      return res.data;
    },
    /**
     *
     * @param {number} application_id
     * @param {number} publishing_id
     */
    async getPublication(application_id, publishing_id) {
      const res = await axiosApiInst({
        url: BASE_URL + "/" + application_id + "/publications/" + publishing_id,
      });
      return res.data;
    }
  };
}
export { createApi, Application };
