<template>
  <b-container fluid class="bg-gray px-0">
    <div class="d-flex">
      <aside class="px-3 pt-3 sidebar">
        <div v-if="$can('view_application')">
          <div class="d-flex justify-content-between mb-1">
            <div class="align-self-end">
              <h3>Applications</h3>
            </div>
            <div class="align-self-center pb-1">
              <b-button
                v-b-toggle.accordion-1
                variant="outline-secondary"
                class="rounded"
                size="sm"
                pill
              >
                <small
                  ><b-icon-chevron-up v-show="applicationMenuShow">
                  </b-icon-chevron-up
                ></small>
                <small
                  ><b-icon-chevron-down v-show="!applicationMenuShow">
                  </b-icon-chevron-down
                ></small>
              </b-button>
            </div>
          </div>
          <b-collapse
            id="accordion-1"
            accordion="my-accordion"
            role="tabpanel"
            v-model="applicationMenuShow"
          >
            <b-button
              variant="danger"
              class="sbar-btn-w mx-auto d-block mb-2"
              :disabled="!$can('create_application')"
              @click="isbnApply"
            >
              <span>
                <b-icon-plus-square-fill></b-icon-plus-square-fill>
              </span>
              <span class="d-inline-block w-75"> New Application </span>
            </b-button>
            <b-toast
              id="cant-apply"
              title="Cannot Apply Yet"
              variant="warning"
              :auto-hide-delay="3000"
            >
              Please complete your profile.
            </b-toast>
            <dash-link to="/" status="all" :count="count(applications)">
              <b-icon-bookmark-dash></b-icon-bookmark-dash>
            </dash-link>
            <dash-link to="/?status=unverified" status="unverified" 
            v-show="!$can('approve_application')"
            :count="count(applications,'unverified')">
              <b-icon-bookmark-dash></b-icon-bookmark-dash>
            </dash-link>
            <dash-link
              to="/?status=verified"
              status="verified"
              :count="count(applications,'verified')"
            >
              <b-icon-bookmark></b-icon-bookmark>
            </dash-link>
            <dash-link
              to="/?status=approved"
              status="approved"
              :count="count(applications,'approved')"
            >
              <b-icon-bookmark-check></b-icon-bookmark-check>
            </dash-link>
            <dash-link
              to="/?status=defered/approver"
              status="defered/approver"
              :count="count(applications,'defered/approver')"
            >
              <b-icon-bookmark-x></b-icon-bookmark-x>
            </dash-link>
            <dash-link
              to="/?status=rejected/verifier"
              status="rejected/verifier"
              v-show="!$can('refuse_application')"
              :count="count(applications,'rejected/verifier')"
            >
              <b-icon-bookmark-x></b-icon-bookmark-x>
            </dash-link>
            <dash-link
              to="/?status=rejected/approver"
              status="rejected/approver"
              :count="count(applications,'rejected/approver')"
            >
              <b-icon-bookmark-x></b-icon-bookmark-x>
            </dash-link>

          </b-collapse>
        </div>
        <div v-if="$can('view_application')">
          <div class="d-flex justify-content-between mb-1">
            <div class="align-self-end">
              <h3>Legal Deposits</h3>
            </div>
            <div class="align-self-center pb-1">
              <b-button
                variant="outline-secondary"
                v-b-toggle.accordion-2
                class="rounded"
                size="sm"
                pill
              >
                <small
                  ><b-icon-chevron-up v-show="legalDepositMenuShow">
                  </b-icon-chevron-up
                ></small>
                <small
                  ><b-icon-chevron-down v-show="!legalDepositMenuShow">
                  </b-icon-chevron-down
                ></small>
              </b-button>
            </div>
          </div>
          <b-collapse
            id="accordion-2"
            accordion="my-accordion"
            role="tabpanel"
            v-model="legalDepositMenuShow"
          >
            <dash-link
              to="/deposits/new_deposit"
              status="New Deposit"
              :count="0"
              v-show="$can('create_application')"
            >
              <b-icon-plus-square-fill></b-icon-plus-square-fill>
            </dash-link>
            <dash-link to="/deposits" status="all" :count="count(deposits)">
              <b-icon-bookmark-dash></b-icon-bookmark-dash>
            </dash-link>
            <dash-link
              to="/deposits?status=unverified"
              status="unverified"
              v-show="!$can('approve_application')"
              :count="count(deposits,'unverified')"
            >
              <b-icon-bookmark-dash></b-icon-bookmark-dash>
            </dash-link>
            <dash-link
              to="/deposits?status=verified"
              status="verified"
              :count="count(deposits,'verified')"
            >
              <b-icon-bookmark></b-icon-bookmark>
            </dash-link>
            <dash-link
              to="/deposits?status=approved"
              status="approved"
              :count="count(deposits,'approved')"
            >
              <b-icon-bookmark-check></b-icon-bookmark-check>
            </dash-link>
            <dash-link
              to="/deposits?status=rejected/verifier"
              v-show="!$can('approve_application')"
              status="rejected/verifier"
              :count="count(deposits,'rejected/verifier')"
            >
              <b-icon-bookmark-x></b-icon-bookmark-x>
            </dash-link>
            <dash-link
              to="/deposits?status=defered/approver"
              status="defered/approver"
              :count="count(deposits,'defered/approver')"
            >
              <b-icon-bookmark-x></b-icon-bookmark-x>
            </dash-link>
            <dash-link
              to="/deposits?status=rejected/approver"
              status="rejected/approver"
              :count="count(deposits,'rejected/approver')"
            >
              <b-icon-bookmark-x></b-icon-bookmark-x>
            </dash-link>
          </b-collapse>
        </div>
        <div v-if="$can('view_users')">
          <h3 class="text-center">Users</h3>
          <hr class="my-1 mb-2 bg-underline" />
          <b-button
            variant="danger"
            class="sbar-btn-w mx-auto d-block mb-2 text-left"
            :disabled="!$can('create_users')"
            v-b-modal.register-user
          >
            <span class="d-inline-block ml-3" style="width: 1.0625em">
              <b-icon-person-plus-fill></b-icon-person-plus-fill>
            </span>
            <span
              class="d-inline-block"
              style="text-indent: 1.125em; width: 6em"
            >
              New User
            </span>
          </b-button>
          <dash-link to="/users" status="active users" :count="0">
            <b-icon-people-fill></b-icon-people-fill>
          </dash-link>
          <dash-link
            to="/users?active=false"
            status="inactive users"
            :count="0"
          >
            <b-icon-people-fill></b-icon-people-fill>
          </dash-link>
        </div>
        <div v-if="$can('view_reports')">
          <h3 class="text-center">Reports</h3>
          <hr class="my-1 mb-2 bg-underline" />
          <b-nav vertical>
            <b-nav-item to="/reports">ISBN Applications</b-nav-item>
            <b-nav-item to="/reports/deposits">Legal Deposits</b-nav-item>
            <b-nav-item to="/reports/payments">Payments</b-nav-item>
          </b-nav>


        </div>
        <div class="sbar-user sbar-btn-w mx-auto">
          <dash-user @show-profile="displayProfile"></dash-user>
        </div>
      </aside>
      <main class="col-9">
        <router-view></router-view>
      </main>
    </div>
    <user-profile></user-profile>
    <register-user></register-user>
    <new-application></new-application>
  </b-container>
</template>
<style lang="scss">
@import "@/assets/custom_scss/main.scss";
.sidebar {
  min-height: 100vh;
  max-width: 18.75em;
  min-width: 16.75em;
  background-color: #ffffff;
  font-family: $font-primary;
}
.sbar-btn-w {
  width: 14.4375em;
}
.sbar-ln {
  padding-left: 1.8em;
  padding-top: 0.45em;
  padding-bottom: 0.45em;
  font-weight: 650;
  margin-bottom: 0.4em;
}
.sbar-ln:hover {
  text-decoration: none;
}
.sbar-user {
  position: fixed;
  bottom: 1em;
}
</style>
<script>
import DashLink from "../components/DashLink.vue";
import DashUser from "../components/DashUser.vue";
import { ACCOUNT_TYPE } from "../modules/auth/utils";
import UserProfile from "../modules/profile/UserProfile.vue";
import RegisterUser from "../modules/users/RegisterUser.vue";
import NewApplication from "../modules/applications/new_application/NewApplication.vue";
import { aroutes } from "../modules/applications";
import droutes from "../modules/deposits/router";
import { axiosApiInst } from "../plugins/axios";
const appls_routes = aroutes.children.map((el) => el.name);
const deposit_routes = droutes.children.map((el) => el.name);
/**
 *
 * @param {string[]} routes
 * @param {string} rname
 */
function isRoute(routes, rname) {
  const elm = routes.find((el) => el == rname);
  return Boolean(elm);
}

export default {
  components: { DashLink, DashUser, UserProfile, RegisterUser, NewApplication },
  computed: {
    applications() {
      return this.$store.getters["application/getApplications"];
    },
    deposits() {
      return this.$store.getters["deposit/getApplications"];
    },
    /**
     * @returns {{account_type:string}}
     */
    account_type() {
      return this.$store.getters["auth/getAccountType"];
    },
    cant_apply() {
      return this.account_type == ACCOUNT_TYPE.UNKNOWN;
    }
  },
  data() {
    return {
      applicationMenuShow: isRoute(appls_routes, this.$route.name),
      legalDepositMenuShow: isRoute(deposit_routes, this.$route.name),
      notifications:[],
    };
  },
  mounted(){
    this.fetchNotifications();
  },
  methods: {
    /**
     *
     * @param {Array<{status:string}>} store
     * @param {string} status
     */
    count(store, status) {
       return status==undefined? store.length :store.filter((el) => {return el["status"] == status;}).length;
    },
    fetchNotifications(){
      axiosApiInst.get(process.env.VUE_APP_BACKEND + "/notifications",{params:{is_read:false}})
      .then((res)=>{
        const DELAY = 5000;
        res.data.forEach((note,idx)=>{
            this.$bvToast.toast(note.message, {
            title: 'Message',
            autoHideDelay: DELAY + (DELAY*(idx+1)) % 7000,
            appendToast: true,
          });
          setTimeout(()=>{this.markRead(note.id)},DELAY);
        });
      });
    },
    markRead(id){
      axiosApiInst.post(process.env.VUE_APP_BACKEND+"/notifications/"+id+"/read").then(()=>{
        this.notifications = this.notifications.filter(note=>note.id!=id);
      });
    },
    isbnApply() {
      if (this.cant_apply) {
        this.$bvToast.show("cant-apply");
        return 0;
      }
      this.$bvModal.show("new-application");
    },
    legalApply() {
      if (this.cant_apply) {
        this.$bvToast.show("cant-apply");
        return 0;
      }
      this.$router.push({ path: "/deposits/new_deposit" });
    },
    displayProfile() {
      this.$bvModal.show("user-profile");
    },
  },
};
</script>
