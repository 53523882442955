import { token } from "../modules/auth/utils";

/**
 * @param {{path:string}} to
 * @param {import("vue-router").NavigationGuardNext} next 
*/
function isLogged(to,next){
    const {path} = to;
    if(!token.isValid() && !path.includes("auth")){
        next("/auth/login");
    }else{
        next();
    }
}
export {isLogged}