<template>
    <b-modal id="new-publisher" hide-footer title="New Publisher" content-class="bg-gray" size="sm">
        <b-form @submit.prevent="addPublisher">
            <b-form-group label="Name">
                <b-form-input :value="name" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Physical Address">
                <b-form-input v-model="form.physical_address" required></b-form-input>
            </b-form-group>
            <b-form-group label="Postal Address">
                <b-form-input v-model="form.postal_address"></b-form-input>
            </b-form-group>
            <b-button variant="danger" type="submit">
                <b-spinner small v-show="saving"></b-spinner> &nbsp;
                ADD PUBLISHER
            </b-button>
        </b-form>   
    </b-modal>
</template>
<script>
import * as api from './api';
export default {
    props:["name"],
    data(){
        return {
            saving:false,
            form:{
                physical_address:"NA",
                postal_address:"NA"
            }
        }
    },
    methods:{
        addPublisher(){
            this.saving = true;
            api.store({...this.form,name:this.name}).then(pub=>{
                this.saving = false;
                this.$emit("new-publ",pub);
                this.$store.dispatch("newapplication/addPublisher",pub).then(()=>{
                    this.$bvModal.hide("new-publisher");
                });
            });
        }
    }
}
</script>