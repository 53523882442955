const DIndex= ()=>import("./Deposits.vue");
const ListDeposits = ()=>import("./ListDeposits.vue");
const NewDeposit = ()=>import("./NewDeposit.vue");
const moduleRoute = {
    path:"deposits",
    component:DIndex,
    children:[
        {
            path:"",
            name:"deposits",
            component:ListDeposits
        },
        {
            path:"new_deposit",
            name:"newdeposit",
            component:NewDeposit
        }
    ]
};
export default moduleRoute;