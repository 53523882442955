import {createApi} from "../api";
const api = createApi("applications");
export default{
    namespaced:true,
    state:{
        publications:[],
        authors:[],
        publishers:[]
    },
    mutations:{
        drop_pub(state,idx){
            const pubs = state.publications;
            pubs.splice(idx,1);
            state.publications = pubs;
        },
        add_pub(state,pub){
            state.publications = [pub,...state.publications]
        },
        set_authors(state,authors){
            state.authors = authors;
        },
        set_publishers(state,publishers){
            state.publishers = publishers;
        },
        add_publisher(state,publisher){
            state.publishers = [publisher,...state.publishers];
        },
        add_author(state,authr){
            state.authors = [authr,...state.authors];
        },
        reset_publications(state){
            state.publications = [];
        }
    },
    getters:{
        getPublications(state){
            return state.publications;
        },
        getPublishers(state){
            return state.publishers.map(el=>el["name"]);
        },
        getFullPublishers(state){
            return state.publishers;
        },
        getAuthors(state){
            return state.authors.map(el=>el["name"]);
        },
        getFullAuthors(state){
            return state.authors;
        }
    },
    actions:{
        savePublication({commit},pub){
            commit("add_pub",pub);
        },
        loadContent({commit}){
            api.getAppData().then((data)=>{
                const {publishers,authors} = data;
                commit("set_publishers",publishers);
                commit("set_authors",authors);
            });
        },
        resetStore({dispatch,commit}){
            dispatch('loadContent');
            commit('reset_publications');
        },
        dropPub({commit},idx){
            commit("drop_pub",idx);
        },
        addPublisher({commit},pub){
            commit("add_publisher",pub);
        },
        addAuthor({commit},authr){
            commit("add_author",authr);
        },
        async savePublications({state,dispatch}){
            const publications = state.publications;

            const data = await api.createApplication(publications);
            dispatch('resetStore');
            return data;
        }
    }
};