<template>
    <b-modal id="user-profile" title="Account" hide-footer content-class="bg-gray" v-model="isShown" @show="loadProfile">
        <profile-nav v-show="current!='profile-dialog'" :value="current"></profile-nav>
        <b-overlay :show="loading" spinner-variant="primary">
            <transition name="fade-x" mode="out-in">
                <component :is="current" @next="view" :key="reload"></component>
            </transition>
        </b-overlay>
    </b-modal>
</template>
<script>
import { ACCOUNT_TYPE } from '../auth/utils'
import BioData from './components/BioData.vue'
import ContactInfo from './components/ContactInfo.vue'
import LocationInfo from './components/LocationInfo.vue'
import ProfileDialog from './components/ProfileDialog.vue'
import ProfileNav from './components/ProfileNav.vue'

const routes = {
    dialog:'profile-dialog',
    biodata:'bio-data',
    contact:'contact-info',
    location:'location-info'
}
export default {
    components:{ProfileDialog, ProfileNav,BioData,ContactInfo,LocationInfo},
    computed:{
        /**@returns {string} */
        account_type(){
            const account_type = this.$store.getters["auth/getAccountType"];
            return account_type;
        }
    },
    mounted(){
        if(this.account_type==ACCOUNT_TYPE.INDIVIDUAL){
            this.current = routes.biodata;
        }
    },
    data(){
        return{
            current:routes.dialog,
            isShown:false,
            loading:false,
            reload:0,
        }
    },
    methods:{
        view(route_name){
            if(route_name=='finish'){
                this.isShown=false;
                this.current=routes.biodata;
                return 0;
            }
            this.current = route_name;
        },
        loadProfile(){
            if(this.account_type==ACCOUNT_TYPE.INDIVIDUAL){
                this.loading = true;
                this.$store.dispatch("uprofile/loadProfile").then(()=>{
                    this.loading = false;
                    this.reload+=1;
                });
            }
        }
    }
}
</script>
<style scoped>
.fade-x-enter,
.fade-x-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.fade-x-enter-active,
.fade-x-leave-to {
  transition: opacity 0.3s, transform 0.5s;
}
</style>