import {axiosApiInst} from "../../plugins/axios";
/**
 * @typedef UserInput
 * @property {string} email
 * @property {string} password
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} role
 */
const ADMIN_URL = process.env.VUE_APP_BACKEND+"/admin";
const api = {
    /**
     * @param {UserInput} user
     * @return {Promise<UserInput>}
     */
    async registerUser(user){
        const pluralize = (role)=>role+'s';
        const res = await axiosApiInst({
            url:ADMIN_URL+"/"+pluralize(user.role),
            data:user,
            method:"post"
        });
        return res.data;
    },

    async listUsers(){
        const res = await axiosApiInst({
            url:ADMIN_URL+"/users"
        }); 
        return res.data;
    }
};
export default api