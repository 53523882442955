<template>
  <b-modal
    id="register-user"
    hide-footer
    content-class="bg-gray"
    title="Register"
  >
    <b-form @submit.prevent="registerUser">
      <b-row>
        <b-col>
          <b-form-group
            label="First Name"
            invalid-feedback="First Name is required"
          >
            <b-form-input
              type="text"
              v-model="$v.form.first_name.$model"
              :state="validateState($v.form.first_name)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Last Name"
            invalid-feedback="Last Name is required"
          >
            <b-form-input
              type="text"
              v-model="$v.form.last_name.$model"
              :state="validateState($v.form.last_name)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Telephone"
            invalid-feedback="Telephone is required"
          >
            <b-form-input
              type="tel"
              v-model="$v.form.phone_no.$model"
              :state="validateState($v.form.phone_no)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Email" invalid-feedback="Email is invalid">
            <b-form-input
              type="email"
              v-model="$v.form.email.$model"
              :state="validateState($v.form.email)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Role" invalid-feedback="Role is invalid">
            <b-form-select
              v-model="$v.form.role.$model"
              :options="roles"
              :state="validateState($v.form.role)"
            >
              <template #first>
                <b-form-select-option value="" disabled
                  >-- Please select role --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button type="submit" variant="danger" class="submit-btn float-right">
        <b-spinner small v-show="submitting"></b-spinner>
        REGISTER
      </b-button>
    </b-form>
    <b-alert dismissible v-model="error.exists" variant="danger">
      {{ error.body }}
    </b-alert>
  </b-modal>
</template>
<style scoped>
.submit-btn {
  width: 10.25em;
}
</style>
<script>
import { validationMixin } from "vuelidate";
import { required, email, helpers } from "vuelidate/lib/validators";
import { RoleTypes, Api } from "../rbac";
const number = helpers.regex("serial",/^07[0-9]{8}$/)
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        role: "",
        email: "",
        phone_no: "",
      },
      roles: [RoleTypes.APPROVER, RoleTypes.VERIFIER,RoleTypes.MANAGER],
      submitting: false,
      error: {
        exists: false,
        body: "",
      },
    };
  },
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      role: { required },
      email: { email },
      phone_no: { required,number },
    },
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    registerUser() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.submitting = true;
      Api.registerUser(this.form)
        .then((user) => {
          this.submitting = false;
          this.$store.dispatch("rbac/addUser", user);
          this.reset();
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.error.exists = true;
            this.error.body = err.response.data["message"];
          }
        });
    },
    reset() {
      this.form.first_name = "";
      this.form.last_name = "";
      this.form.role = "";
      this.form.email = "";
      this.form.phone_no = "";
      this.$v.form.$reset();
    },
  },
};
</script>